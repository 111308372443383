'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _templateObject = _taggedTemplateLiteral(['\n  filter: ', ';\n'], ['\n  filter: ', ';\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _propTypes = require('prop-types');

var _mapCssFilters = require('map-css-filters');

var _mapCssFilters2 = _interopRequireDefault(_mapCssFilters);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var FilterWrapper = _styledComponents2.default.div(_templateObject, function (props) {
  return (0, _mapCssFilters2.default)(props.effects);
});

var Filter = function Filter(_ref) {
  var children = _ref.children,
      props = _objectWithoutProperties(_ref, ['children']);

  return _react2.default.createElement(
    FilterWrapper,
    props,
    children
  );
};

Filter.propTypes = {
  effects: _propTypes.object.isRequired,
  children: _propTypes.node
};

exports.default = Filter;